import React, { createContext, useState, useEffect, useContext } from 'react';
import Papa from 'papaparse';
import { CityMaster } from '../types';

type CityMasterContextType = {
    cityMaster: CityMaster[];
    isLoading: boolean;
    error: string | null;
};

const CityMasterContext = createContext<CityMasterContextType | undefined>(undefined);

export const CityMasterProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [cityMaster, setCityMaster] = useState<CityMaster[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        Papa.parse('/tabichizu_city_master_city_master_20240327.csv', {
            download: true,
            header: true,
            complete: (results) => {
                setCityMaster(results.data as CityMaster[]);
                setIsLoading(false);
            },
            error: (error) => {
                setError('Failed to load city master data');
                setIsLoading(false);
            }
        });
    }, []);

    return (
        <CityMasterContext.Provider value={{ cityMaster, isLoading, error }}>
            {children}
        </CityMasterContext.Provider>
    );
};

export const useCityMaster = () => {
    const context = useContext(CityMasterContext);
    if (context === undefined) {
        throw new Error('useCityMaster must be used within a CityMasterProvider');
    }
    return context;
};
