import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';

interface ProductCardProps {
    product: {
        itemName: string;
        itemPrice: number;
        itemCaption: string;
        mediumImageUrls: { imageUrl: string }[];
        itemUrl: string;
    };
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                paddingTop: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={product.mediumImageUrls[0].imageUrl.replace('_ex=128x128', '_ex=400x400')}
                                alt={`${product.itemName} - Main Image`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                paddingTop: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={product.mediumImageUrls[1]?.imageUrl.replace('_ex=128x128', '_ex=400x400')}
                                alt={`${product.itemName} - Image 2`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                paddingTop: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={product.mediumImageUrls[2]?.imageUrl.replace('_ex=128x128', '_ex=400x400')}
                                alt={`${product.itemName} - Image 3`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography gutterBottom variant="h6" component="h2" sx={{
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    lineHeight: 1.2,
                    height: '2.4em'
                }}>
                    {product.itemName}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{
                    mb: 2,
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    lineHeight: 1.5,
                    height: '4.5em'
                }}>
                    {product.itemCaption}
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                    <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                        ¥{product.itemPrice.toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem', display: 'block', mb: 1 }}>
                        最新情報は販売サイトをご確認ください
                    </Typography>
                    <Button variant="contained" color="primary" href={product.itemUrl} target="_blank" rel="noopener noreferrer" fullWidth>
                        詳細を見る
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProductCard;
