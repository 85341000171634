import React from 'react';
import { AppBar, Toolbar, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{
                backgroundColor: '#f58500', // オレンジ色の背景
            }}
        >
            <Container maxWidth="lg">
                <Toolbar disableGutters sx={{ justifyContent: 'center', minHeight: 'auto' }}>
                    <Box
                        component={Link}
                        to="/"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="/logo.png"
                            alt="Logo"
                            style={{ height: 40 }}
                        />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
