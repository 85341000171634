// src/pages/CityPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Grid, Box, Container, CircularProgress, Button, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { useCityMaster } from '../contexts/CityMasterContext';
import ProductList from '../components/ProductList';
import { Product, CityMaster } from '../types';

const BASE_URL = 'https://souvenir-data.tripkt.com/data';
const GENRE = '100227';
const INITIAL_PAGE = 1;

type SortOrder = 'default' | 'priceAsc' | 'priceDesc';

const CityPage: React.FC = () => {
    const { cityCode } = useParams<{ cityCode: string }>();
    const { cityMaster, isLoading: isCityMasterLoading, error: cityMasterError } = useCityMaster();
    const [products, setProducts] = useState<Product[]>([]);
    const [sortedProducts, setSortedProducts] = useState<Product[]>([]);
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [productError, setProductError] = useState<string | null>(null);
    const [nearbyCities, setNearbyCities] = useState<CityMaster[]>([]);
    const [sortOrder, setSortOrder] = useState<SortOrder>('default');

    const cityData = cityMaster.find(city => city.cityCode === cityCode);

    useEffect(() => {
        if (cityData && cityCode) {
            setIsProductLoading(true);
            fetch(`${BASE_URL}/${cityCode}_${GENRE}_${INITIAL_PAGE}.json`)
                .then(response => response.json())
                .then(data => {
                    setProducts(data.Items);
                    setSortedProducts(data.Items);
                    setIsProductLoading(false);
                })
                .catch(err => {
                    setProductError('Failed to load product data');
                    setIsProductLoading(false);
                });

            const nearby = cityMaster
                .filter(city => city.prefectureCode === cityData.prefectureCode && city.cityCode !== cityCode)
                .sort(() => 0.5 - Math.random())
                .slice(0, 15);
            setNearbyCities(nearby);
        }
    }, [cityCode, cityData, cityMaster]);

    useEffect(() => {
        const sortProducts = (order: SortOrder) => {
            const sorted = [...products];
            switch (order) {
                case 'priceAsc':
                    sorted.sort((a, b) => a.Item.itemPrice - b.Item.itemPrice);
                    break;
                case 'priceDesc':
                    sorted.sort((a, b) => b.Item.itemPrice - a.Item.itemPrice);
                    break;
                default:
                    break;
            }
            setSortedProducts(sorted);
        };

        sortProducts(sortOrder);
    }, [sortOrder, products]);

    const handleSortChange = (event: SelectChangeEvent<SortOrder>) => {
        setSortOrder(event.target.value as SortOrder);
    };

    if (isCityMasterLoading || isProductLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="primary" />
            </Box>
        );
    }

    if (cityMasterError) return <Typography color="error">Error loading city data: {cityMasterError}</Typography>;
    if (!cityData) return <Typography color="error">City not found</Typography>;
    if (productError) return <Typography color="error">Error loading product data: {productError}</Typography>;

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {cityData.prefectureName} {cityData.cityFullNameJa}のお土産
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="sort-select-label">並び替え</InputLabel>
                        <Select<SortOrder>
                            labelId="sort-select-label"
                            id="sort-select"
                            value={sortOrder}
                            onChange={handleSortChange}
                            label="並び替え"
                        >
                            <MenuItem value="default">デフォルト</MenuItem>
                            <MenuItem value="priceAsc">価格が安い順</MenuItem>
                            <MenuItem value="priceDesc">価格が高い順</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <ProductList products={sortedProducts} />
                <Box sx={{ fontSize: '0.8rem', color: 'text.secondary', mt: 4, mb: 2, textAlign: 'center' }}>
                    ※ 本サイトはアフィリエイトを利用しております。
                </Box>
                <Box sx={{ mt: 6 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        近隣の市区町村のお土産も見てみよう
                    </Typography>
                    <Grid container spacing={2}>
                        {nearbyCities.map((city) => (
                            <Grid item key={city.cityCode}>
                                <Button
                                    component={Link}
                                    to={`/${city.cityCode}`}
                                    variant="outlined"
                                    color="primary"
                                >
                                    {city.cityFullNameJa}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default CityPage;
