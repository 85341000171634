import React from 'react';
import { Grid } from '@mui/material';
import ProductCard from './ProductCard';
import { Product } from '../types';

interface ProductListProps {
    products: Product[];
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
    return (
        <Grid container spacing={3}>
            {products.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <ProductCard product={product.Item} />
                </Grid>
            ))}
        </Grid>
    );
};

export default ProductList;
