import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CityMasterProvider } from './contexts/CityMasterContext';
import Layout from './components/Layout';
import CityPage from './pages/CityPage';

const theme = createTheme({
    palette: {
        primary: {
            main: '#f58500',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#2196f3',
        },
        background: {
            default: '#fff9f0',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 600,
            color: '#f58500',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 4px 6px rgba(245,133,0,0.1)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 6px 12px rgba(245,133,0,0.15)',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: '#ff9800',
                    },
                },
            },
        },
    },
});

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <CityMasterProvider>
                <Router>
                    <Layout>
                        <Routes>
                            <Route path="/:cityCode" element={<CityPage />} />
                        </Routes>
                    </Layout>
                </Router>
            </CityMasterProvider>
        </ThemeProvider>
    );
};

export default App;
